import React, { Component } from 'react';
import {
    Switch,
    Route,
    withRouter
  } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import ContentPanel from '../ContentPanel/ContentPanel';
import Code from '../Code/Code';


class AnimatedSwitch extends Component {
    constructor(props){
        super(props);
        this.state = {
            dynamicclass:'entered'
        }
    }

    handleEntered(){
        this.setState({dynamicclass:'entered'});
    }

    handleEntering(){
        this.setState({dynamicclass:'on-entering'});
    }

    getClassname(){
        return `section-wrapper ${this.state.dynamicclass}`;
    }

    render(){
        return (
            <TransitionGroup className={this.getClassname()}>
                <CSSTransition key={this.props.history.location.key} classNames="section" timeout={1000} onEntered={this.handleEntered.bind(this)} onEntering={this.handleEntering.bind(this)}>
                <Switch location={this.props.history.location}>
                    <Route path="/" exact>
                        <ContentPanel contentnode="home" />
                    </Route>
                    <Route path="/code" component={Code} />
                    <Route path="/contact">
                        <ContentPanel contentnode="contact" />
                    </Route>
                    <Route path="/info">
                        <ContentPanel contentnode="info" />
                    </Route>
                </Switch>
                </CSSTransition>
            </TransitionGroup>
        )
    }
}

export default withRouter(AnimatedSwitch);
