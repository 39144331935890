import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './ContentPanel.module.css';

export default class ContentPanel extends Component {
    constructor(props){
        super(props);
        this.state = {
            mounted: false
          };
    }
    componentDidMount() {
        this.setState({ mounted: true });
      }
    
    componentWillUnmount() {
        this.setState({ mounted: false });
    }
    render() {
        if(this.props.contentnode === 'home'){
            return (
                <div className="section-inner-wrapper">
                    <h2>Hi. I’m Sarah McNally. </h2>
                    <p>I'm an experienced lead developer with over 18 years in the industry.</p>
    <p>
    I have a background in creative web development, and most recently have been leveraging that background to supercharge AEM development, with a streamlined front-to-backend development workflow. </p><p>Other recent focuses include React, canvas manipulation, and Three.js. Site performance, accessibility and best practices are all near and dear to my heart.
                    </p>
                </div>
            );
        }else if(this.props.contentnode === 'contact'){
            return (
                <div className="section-inner-wrapper">
                    <div className={styles.contact}>
                        <h2>There are many ways to reach me.</h2>
                        <h3>General</h3>
                        <a href="mailto:sarah@mcnally.info"><FontAwesomeIcon icon={['fa', 'envelope']} />sarah@mcnally.info</a>
                        <h3>Social Media</h3>
                        <a href="https://www.linkedin.com/in/sarahlamont/"><FontAwesomeIcon icon={['fab', 'linkedin']} />Linkedin</a>
                        <a href="https://twitter.com/seriflafont"><FontAwesomeIcon icon={['fab', 'twitter']} />Twitter</a>
                        
                        <h3>Code Communities</h3>
                        <a href="http://www.github.com/seriflafont"><FontAwesomeIcon icon={['fab', 'github']} />Github</a>
                        <a href="https://codepen.io/smcnally000"><FontAwesomeIcon icon={['fab', 'codepen']} />Codepen</a>
                    </div>
                </div>
            );
        }else{
            return (
            <div className="section-inner-wrapper">
                    <h2>So many frameworks, so little time.</h2>
                    <p>I try my best to stay current with the latest frameworks and methodologies. Here's a breakdown of my current skillset.</p>
                    <ul className={styles.skilllist}>
                        <li>508/Accessibility Coding Practices</li>
                        <li>AEM Front and Backend Development (Java Backend - working knowledge)</li>
                        <li>Agile Development Methodology and Sprint planning</li>
                        <li>Client-side Data Population (Api calls, AJAX, Json parsing)</li>
                        <li>Continuous Integration Workflow (Use of Bamboo, Jenkins, etc)</li>
                        <li>CSS Preprocessers/Compilers - Sass, Less, Post-CSS</li>
                        <li>CSS Modules</li>
                        <li>Current Standard Front-end Toolkit (HTML/HTML5 , CSS/CSS3, Javascript - Native/ES6)</li>
                        <li>Demandbase Integration</li>
                        <li>HTML5 Canvas manipulation (and some 3d/webGL)</li>
                        <li>Javascript Frameworks (React - strong knowledge; Angular - working knowledge; Vue.js - working knowledge; Gatsby - familiarity)</li>
                        <li>Javascript Libraries (like jQuery and Dojo)</li>
                        <li>Javascript task runners/bundlers (NPM, Webpack, Grunt, Gulp)</li>
                        <li>Javascript unit testing familiarity</li>
                        <li>Node.js familiarity</li>
                        <li>Performance and SEO optimization</li>
                        <li>Progressive web app development (Ionic Framework)</li>
                        <li>Responsive/Mobile Web Coding best practices/methods</li>
                        <li>Social API familiarity (like Twitter and Facebook)</li>
                        <li>Various Collaboration Tools (SVN, Git, Confluence, JIRA, etc)</li>
                    </ul>
                </div>
            );
        }
        
    }
}
