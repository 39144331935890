import React, {Component} from 'react';
import {
  BrowserRouter as Router,
} from "react-router-dom";

import Nav from './components/Nav/Nav';
import AnimatedSwitch from './components/AnimatedSwitch/AnimatedSwitch';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faHome, faAngleRight, faCaretDown, faExternalLinkSquareAlt, faEnvelope, faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import './styles/global.css';


export default class App extends Component {
  constructor(props){
    super(props);
    library.add(fab, faHome, faAngleRight, faCaretDown, faExternalLinkSquareAlt, faEnvelope, faBars, faTimes);
    this.state = {
      section:'info'
    }
  }
  enterHandler(){
    console.log('enter');
  }
  render(){
      return (
        <div className="App">
          <Router>
            <Nav />
            <AnimatedSwitch enterHandler={this.enterHandler} />
            </Router>
        </div>
      );
  }

}
