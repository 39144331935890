import React, { Component } from 'react';
import {parseStringPromise} from 'xml2js';
import styles from './Code.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import pssite from '../../images/800/ps-site800w.png';
import pssitewp from '../../images/800/ps-site800w.webp';
import psccourse from '../../images/800/ps-crashcourse800w.png';
import psccoursewp from '../../images/800/ps-crashcourse800w.webp';
import covid from '../../images/800/covid-iq800w.png';
import covidwp from '../../images/800/covid-iq800w.webp';
import srsplash from '../../images/800/sr-splash800w.png';
import srsplashwp from '../../images/800/sr-splash800w.webp';
import srsite from '../../images/800/sr-site800w.png';
import srsitewp from '../../images/800/sr-site800w.webp';
import {Picture} from 'react-responsive-picture';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

export default class Code extends Component {
    constructor(props){
        super(props);
        this.state = {
            codepenfeedloaded:false,
            codepenfeed:[],
            codepenerror:false,
            githubfeedloaded:false,
            githubfeed:[],
            githuberror:true
        }
    }
    componentDidMount(){
        this.getCodepenFeed();
        this.getGithubFeed();
    }
    getCodepenFeed(){
        let scope = this;
        // full feed : https://codepen.io/smcnally000/public/feed
        //https://codepen.io/collection/nMzmjJ/feed
        fetch('https://codepen.io/collection/nMzmjJ/feed')
        .then(response => response.text())
        .then(responseText => {
            parseStringPromise(responseText /*, options */).then(function (result) {
                //console.log(result);
                scope.setState({
                    codepenfeed:result.rss.channel[0].item,
                    codepenfeedloaded:true
                });
              })
              .catch(function (err) {
                console.log('Error parsing the feed: ', err);
                scope.setState({
                    codepenfeed:[],
                    codepenfeedloaded:true,
                    codepenerror:true
                });
              });
        })
        .catch((error) => {
            console.log('Error fetching the feed: ', error);
            scope.setState({
                codepenfeed:[],
                codepenfeedloaded:true,
                codepenerror:true
            });
        });
    }

    getGithubFeed(){
        fetch('https://api.github.com/users/seriflafont/repos')
        .then(response => response.json())
        .then(json => {
            //console.log(json)
            if(json !== undefined){
                this.setState({
                    githubfeed:json,
                    githubloaded:true,
                    githuberror:false
                });
            }
        });
    }

    toggleAccordion(e){
        let pad = 50;
        let btn = e.currentTarget;
        let el = btn.nextSibling;
        let str = btn.getAttribute('data-open');
        let b = str === 'true' ? true : false;
        let h = el.getElementsByClassName('info-wrapper')[0].offsetHeight + pad;
        if(!b){
            el.setAttribute('style',`height: ${h}px`);
            btn.classList.add('open');
        }else{
            //console.log('close');
            el.setAttribute('style',`height: 0px`);
            btn.classList.remove('open');
        }
        btn.setAttribute('data-open',!b);
    }

    getCodepenContent(){
        if(!this.state.codepenerror){
            return (
                <div className={styles.codesection}>
                    <h2>Other Stuff</h2>
                    <p>Here's some pens I've done recently via Codepen, that vary from simple to complex.</p>
                    <ul className={styles.codepenEntries}>
                    {this.state.codepenfeed.map((entry, index)=>{
                        return(
                            <li key={`codepenfeeditem${index}`}>
                                <div className={styles.workitemImage}><a className="image-link" href={entry.link} target="_blank" rel="noopener noreferrer"><img src={`${entry.link}/image/small.png`} alt={`${entry.title} screenshot`} /></a></div>
                                <div className={styles.workitemContent}>
                                <strong>{entry.title}</strong>
                                <a className={styles.uilink} href={entry.link} target="_blank" rel="noopener noreferrer">View Pen <FontAwesomeIcon icon="external-link-square-alt" /></a>
                                </div>
                            </li>
                        )
                    })}
                    </ul>
                </div>
            );
        }else{
            return (
                <div></div>
            )
        }
    }

    getGithubContent(){

    }
    render() {
        return (
            <div className="section-inner-wrapper">
                <div className={styles.codesection}>
                    <h2>Recent Work</h2>
                    <p>Over the course of my career, I've developed dozens (maybe hundreds) of websites. Since my recent role was tech lead for the internal marketing dev team of Publicis Sapient, most of my latest work involves the digital ecosystem of the company, with a few side-projects here and there.</p>
                    <ul className={styles.worklist}>
                        <li><div className={styles.workitemImage}><a className="image-link" href="http://www.publicissapient.com" target="_blank" rel="noopener noreferrer">
                        <span className='sr-only'>Go to PublicisSapient.com</span>
                                <LazyLoadComponent>
                                    <Picture
                                        sources = {[
                                            {
                                                srcSet: `${pssitewp}`,
                                                type: "image/webp"
                                            },
                                            {
                                                srcSet: `${pssite}`,//desktop 1x desktop 2x
                                            }
                                            
                                        ]}
                                        alt="Publicis Sapient Website screenshot" 
                                    />
                                </LazyLoadComponent>
                                {/* <LazyLoadImage
                                    alt="Publicis Sapient Website screenshot"
                                    effect="blur"
                                    src={pssite} /> */}
                            </a></div>
                            <div className={styles.workitemContent}>
                                <strong>Publicis Sapient Digital Ecosystem</strong><a className={styles.uilink} href="http://www.publicissapient.com" target="_blank" rel="noopener noreferrer">main site link <FontAwesomeIcon icon="external-link-square-alt" /></a>
                                <p className={styles.techstack}>Tech stack: ES6, Webpack, Babel, Sass, Gulp/NPM, AEM, Java, Bamboo</p>
                                {/* <a className={styles.uilink} href="http://www.publicissapient.com" target="_blank" rel="noopener noreferrer">main site link <FontAwesomeIcon icon="external-link-square-alt" /></a> */}
                                <button onClick={this.toggleAccordion.bind(this)} data-open={false} className={styles.uilink}>More info <FontAwesomeIcon className="icon" icon="angle-right" /></button>
                                <div className={styles.moreInfo}>
                                    <div className="info-wrapper">
                                        <p>While working as tech lead for the Publicis Sapient digital marketing team, I led the development effort to create an enterprise-level digital ecosystem for Publicis Sapient built upon AEM CMS. As part of this, I streamlined the front-to-backend workflow, adhering to best practices in performance, accessibility and SEO. The site included an extensive suite of components, key word tagging, filtering, and a custom workflow for regional site content publishing. Lighthouse scores for Performance, SEO and Accessibility were all above 90%.
                                        </p><p>
                                        The digital ecosystem included, among others: <br />
                                        <a href="http://www.publicissapient.com" target="_blank" rel="noopener noreferrer">publicissapient.com</a><br />
                                        <a href="http://www.publicissapient.cn" target="_blank" rel="noopener noreferrer">publicissapient.cn</a><br />
                                        <a href="http://www.publicissapient.jp" target="_blank" rel="noopener noreferrer">publicissapient.jp</a><br />
                                        <a href="http://www.publicissapient.fr" target="_blank" rel="noopener noreferrer">publicissapient.fr</a><br />
                                        <a href="http://www.publicissapient.de" target="_blank" rel="noopener noreferrer">publicissapient.de</a><br />
                                        <a href="http://careers.publicissapient.com" target="_blank" rel="noopener noreferrer">careers.publicissapient.com</a><br />
                                        <a href="http://careers.publicissapient.fr" target="_blank" rel="noopener noreferrer">careers.publicissapient.fr</a><br />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li><div className={styles.workitemImage}>
                        <a className="image-link" href="http://www.covid-iq.com" target="_blank" rel="noopener noreferrer">
                        <span className='sr-only'>Go to Covid-iq.com</span>
                            <LazyLoadComponent>
                                <Picture
                                    sources = {[
                                        {
                                            srcSet: `${covidwp}`,
                                            type: "image/webp"
                                        },
                                        {
                                            srcSet: `${covid}`,//desktop 1x desktop 2x
                                        }
                                        
                                    ]}
                                    alt="Covid-IQ Website screenshot" 
                                />
                            </LazyLoadComponent>
                            {/* <img src={covid} alt="Covid-IQ Website screenshot" /> */}
                        </a></div>
                            <div className={styles.workitemContent}>
                                <strong>Covid-19 IQ Quiz/Website</strong><a className={styles.uilink} href="http://www.covid-iq.com" target="_blank" rel="noopener noreferrer">site link <FontAwesomeIcon icon="external-link-square-alt" /></a>
                                <p className={styles.techstack}>Tech stack: ReactJS, Webpack, Babel, Sass, Grunt/NPM</p>
                                <button onClick={this.toggleAccordion.bind(this)} data-open={false} className={styles.uilink}>More info <FontAwesomeIcon className="icon" icon="angle-right" /></button>
                                <div className={styles.moreInfo}>
                                <div className="info-wrapper">
                                    <p>As a personal side-project, in an effort to make a teeny tiny difference during the crazy 2020 Covid-19 pandemic crisis, I developed this quiz to share on social media. The day I sent it out it was shared over 500 times across the world. 
                                    </p></div>
                                </div>
                            </div>
                        </li>
                        <li><div className={styles.workitemImage}><a className="image-link" href="http://pscrashcourse.publicissapient.com" target="_blank" rel="noopener noreferrer">
                        <span className='sr-only'>Go to PScrashcourse.com</span>
                            <LazyLoadComponent>
                                <Picture
                                    sources = {[
                                        {
                                            srcSet: `${psccoursewp}`,
                                            type: "image/webp"
                                        },
                                        {
                                            srcSet: `${psccourse}`,//desktop 1x desktop 2x
                                        }
                                        
                                    ]}
                                    alt="Publicis Sapient Crashcourse Web app screenshot" 
                                />
                            </LazyLoadComponent>
                            {/* <img src={psccourse} alt="Publicis Sapient Crashcourse Web app screenshot" /> */}
                        </a></div>
                            <div className={styles.workitemContent}>
                                <strong>PS Crash Course PWA</strong><a className={styles.uilink} href="http://pscrashcourse.publicissapient.com" target="_blank" rel="noopener noreferrer">site link <FontAwesomeIcon icon="external-link-square-alt" /></a>
                                <p className={styles.techstack}>Tech stack: ReactJS + Ionic (TS), Webpack, Babel, Sass, NPM</p>
                                <button onClick={this.toggleAccordion.bind(this)} data-open={false} className={styles.uilink}>More info <FontAwesomeIcon className="icon" icon="angle-right" /></button>
                                <div className={styles.moreInfo}>
                                    <div className="info-wrapper">
                                    <p>During my tenure as tech lead of the digital marketing team, I developed this web app for the hiring team's use at career fairs. It was available for offline use and could be 'installed' on their iPads to enable ease of use.
                                    </p></div>
                                </div>
                            </div>
                        </li>
                        <li><div className={styles.workitemImage}><a className="image-link" href="https://web.archive.org/web/20180628063448/https://www.sapientrazorfish.com/" target="_blank" rel="noopener noreferrer">
                        <span className='sr-only'>Go to the archived Sapientrazorfish.com</span>
                            <LazyLoadComponent>
                                <Picture
                                    sources = {[
                                        {
                                            srcSet: `${srsitewp}`,
                                            type: "image/webp"
                                        },
                                        {
                                            srcSet: `${srsite}`,//desktop 1x desktop 2x
                                        }
                                        
                                    ]}
                                    alt="SapientRazorfish Website screenshot" 
                                />
                            </LazyLoadComponent>
                            {/* <img src={srsite} alt="SapientRazorfish Website screenshot" /> */}
                        </a></div>
                            <div className={styles.workitemContent}>
                                <strong>Sapient Razorfish Website</strong><a className={styles.uilink} href="https://web.archive.org/web/20180628063448/https://www.sapientrazorfish.com/" target="_blank" rel="noopener noreferrer">archived site link <FontAwesomeIcon icon="external-link-square-alt" /></a>
                                <p className={styles.techstack}>Tech stack: ES6, Webpack, Babel, Sass, Grunt/NPM, Hippo, Java</p>
                                <button onClick={this.toggleAccordion.bind(this)} data-open={false} className={styles.uilink}>More info <FontAwesomeIcon className="icon" icon="angle-right" /></button>
                                <div className={styles.moreInfo}>
                                    <div className="info-wrapper">
                                    <p>When Sapient and Razorfish merged to become SapientRazorfish, I led development of the new site. Hippo CMS was the CMS platform; a modular ES6 Javascript architecture compiled with babel/webpack was used for the front-end. This site included various templates and an extensive component library.
                                    </p></div>
                                </div>
                            </div>
                        </li>
                        <li><div className={styles.workitemImage}><a className="image-link" href="https://web.archive.org/web/20170601005759/https://www.sapientrazorfish.com/" target="_blank" rel="noopener noreferrer">
                        <span className='sr-only'>Go to the archived Splash page Sapientrazorfish.com</span>
                            <LazyLoadComponent>
                                <Picture
                                    sources = {[
                                        {
                                            srcSet: `${srsplashwp}`,
                                            type: "image/webp"
                                        },
                                        {
                                            srcSet: `${srsplash}`,//desktop 1x desktop 2x
                                        }
                                        
                                    ]}
                                    alt="SapientRazorfish Brand Launch screenshot" 
                                />
                            </LazyLoadComponent>
                            {/* <img src={srsplash} alt="SapientRazorfish Brand Launch screenshot" /> */}
                        </a></div>
                            <div className={styles.workitemContent}>
                                <strong>Sapient Razorfish Brand Launch Website</strong><a className={styles.uilink} href="https://web.archive.org/web/20170601005759/https://www.sapientrazorfish.com/" target="_blank" rel="noopener noreferrer">archived site link <FontAwesomeIcon icon="external-link-square-alt" /></a>
                                <p className={styles.techstack}>Tech stack: Three.js, ES5, Sass, Grunt/NPM, jQuery</p>
                                <button onClick={this.toggleAccordion.bind(this)} data-open={false} className={styles.uilink}>More info <FontAwesomeIcon className="icon" icon="angle-right" /></button>
                                <div className={styles.moreInfo}>
                                    <div className="info-wrapper">
                                    <p>This was the 'splash' site developed for the brand launch announcement of the newly formed SapientRazorfish. I developed this page as a single scrolling page. The intro screen included an interactive starfield developed with Three.js, and the scrolling content included parallax effects.
                                    </p></div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                {this.getCodepenContent()}
            </div>
        )
    }
}
