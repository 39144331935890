import React, { Component } from 'react';
import {withRouter, Link} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './Nav.module.css';

class Nav extends Component {
    constructor(props){
        super(props);
        this.state = {
            currentpath:this.props.history.location.pathname,
            ismobile:false,
            navopen:true,
            collapsedstate:false
        }
        this.mobilebreakpoint = Number(getComputedStyle(document.documentElement).getPropertyValue('--mobile-breakpoint').replace(/px/,''));
        this.currentglobalpad = Number(getComputedStyle(document.documentElement).getPropertyValue('--global-layout-pad').replace(/px/,''));
        
    }
    componentDidMount() {
        this.unlisten = this.props.history.listen((location, action) => {
            this.setState({
                currentpath: location.pathname,
            });
            this.onWindowScroll();
        });
        this.header = document.getElementsByClassName(styles.header)[0];
        this.socialnav = document.getElementsByClassName(styles.social)[0];
        this.navtracker = document.getElementsByClassName(styles.navtracker)[0];
        this.element = document.getElementsByClassName(styles.wrapper)[0];
        this.lastsociallink = document.querySelector(`.${styles.social} li:last-child a`);
        this.firstnavlink = document.querySelector(`.${styles.nav} li:first-child a`);
        this.lastnavlink = document.querySelector(`.${styles.nav} li:last-child a`);
        this.navlinks = document.querySelectorAll(`.${styles.nav} a`);
        this.menuddbutton = document.querySelector(`.${styles.mobileNav} button`)
        window.addEventListener('resize',this.onWindowResize.bind(this));
        window.addEventListener('orientationchange',this.onWindowResize.bind(this));
        window.addEventListener('scroll',this.onWindowScroll.bind(this));
        this.lastsociallink.addEventListener('blur',this.onLastSocialLinkBlur.bind(this));
        this.lastnavlink.addEventListener('blur',this.onLastNavLinkBlur.bind(this));
        this.onWindowResize();
    }

    onWindowScroll(){
        let hitY = this.currentglobalpad + this.header.offsetHeight;
        let t = this.navtracker.getBoundingClientRect().top;
        if(t <= hitY || this.state.ismobile){
            //if the nav is either in mobile mode or it goes past the top, collapse it. Or if the user starts to scroll and it's in open state but in mobile mode or past the top, collapse.
            if(!this.element.classList.contains(`${styles.wrapperCollapsed}`)) {
                this.element.classList.add(`${styles.wrapperCollapsed}`);
                this.setState({
                    navopen:false,
                    collapsedstate:true
                });
                this.toggleOffLinkTabbing();
            }
            
            if(this.element.classList.contains(`${styles.openNav}`)) {
                this.element.classList.remove(`${styles.openNav}`);
                this.setState({
                    navopen:false
                });
                this.toggleOffLinkTabbing();
            }
        }else{
            if(this.element.classList.contains(`${styles.wrapperCollapsed}`)) {
                this.element.classList.remove(`${styles.wrapperCollapsed}`);
                this.setState({
                    navopen:true,
                    collapsedstate:false
                });
                this.toggleOnLinkTabbing();
            };
        }
    }

    onWindowResize(){
        this.currentglobalpad = Number(getComputedStyle(document.documentElement).getPropertyValue('--global-layout-pad').replace(/px/,''));
        let ww = window.innerWidth;
        this.setState({
            ismobile:ww<this.mobilebreakpoint?true:false
        },()=>{
            this.onWindowScroll();
            let s = this.socialnav.offsetWidth;
            if(this.state.ismobile){
                document.documentElement.style.setProperty('--content-rightpad', "var(--global-element-pad)");
            }else{
                document.documentElement.style.setProperty('--content-rightpad', s + "px");
            }
        });

        let h = this.header.offsetHeight;
        document.documentElement.style.setProperty('--header-height', h + "px");
        
        let wh = window.innerHeight;
        document.documentElement.style.setProperty('--window-height', wh + "px");
    }
    componentWillUnmount() {
        this.unlisten();
    }
    onLastSocialLinkBlur(){
        if(this.state.navopen) {
           this.firstnavlink.focus();
        }
    }
    onLastNavLinkBlur(){
        if(this.state.collapsedstate) {
            this.menuddbutton.focus();
         }
    }
    toggleOnLinkTabbing(){
        this.navlinks.forEach((a) => {
            a.setAttribute('tabindex','0');
          });
    }

    toggleOffLinkTabbing(){
        this.navlinks.forEach((a) => {
          a.setAttribute('tabindex','-1');
        });
    }
    handleMobileNavClick(){
        if(!this.element.classList.contains(`${styles.openNav}`)) {
            this.element.classList.add(`${styles.openNav}`);
            this.setState({
                navopen:true
            });
            this.toggleOnLinkTabbing();

        }else{
            this.element.classList.remove(`${styles.openNav}`);
            this.setState({
                navopen:false
            });
            this.toggleOffLinkTabbing();
        }
    }

    render() {
        return (
            <div className={styles.wrapper}>
                <header className={styles.header}>
                    <div className={styles.mobileNav}><button onClick={this.handleMobileNavClick.bind(this)} className={styles.uilink}>
                        <span className="sr-only">Click here to toggle navigation</span>
                        <FontAwesomeIcon className={styles.openBtn} icon="bars" size="lg" /><FontAwesomeIcon className={styles.closeBtn} icon="times" size="lg" />
                    </button></div>
                    <Link className={this.state.currentpath === '/' ? styles.selected : ''} to="/"><h1>hello.</h1></Link>
                    <ul className={styles.social}>
                        <li><a href="mailto:sarah@mcnally.info" rel="noopener noreferrer"><FontAwesomeIcon icon={['fa', 'envelope']} size="lg" /><span className="sr-only">sarah@mcnally.info</span></a></li>
                        <li><a href="https://www.linkedin.com/in/sarahlamont/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'linkedin']} size="lg" /><span className="sr-only">Linkedin</span></a></li>
                        <li><a href="https://twitter.com/seriflafont" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'twitter']} size="lg" /><span className="sr-only">Twitter</span></a></li>
                        <li><a href="http://www.github.com/seriflafont" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'github']} size="lg" /><span className="sr-only">Github</span></a></li>


                            {/* <a href="https://codepen.io/smcnally000"><FontAwesomeIcon icon={['fab', 'codepen']} />Codepen</a> */}
                    </ul>
                </header>
                <div className={styles.navtracker}></div>
                <ul className={styles.nav}>
                    <li className={this.state.currentpath === '/info' ? styles.selected : ''}><Link to="/info">info</Link></li>
                    <li className={this.state.currentpath === '/code' ? styles.selected : ''}><Link to="/code">code</Link></li>
                    <li className={this.state.currentpath === '/contact' ? styles.selected : ''}><Link to="/contact">contact</Link></li>
                </ul>
            </div>
        )
    }
}
export default withRouter(Nav)